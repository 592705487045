<template>
    <article-case v-if="caseID !== ''" :caseID="caseID"></article-case>
</template>

<script>
// @ is an alias to /src
import ArticleCase from '../components/layouts/ArticleCase.vue'

export default {
    name: 'Case',
    components: {
        ArticleCase
    },
    data() {
        return {
            caseID: ''
        }
    },
    mounted() {
        this.caseID = this.$route.params.caseID;
    }
}
</script>

<style scoped>

</style>
